import React from 'react'
import ContactH from '../components/contactPages/ContactH'
import Navbar from '../components/Navbar'

const Contact = () => {
  return (
      <>
    <div className="bg-texture cursor-none h-full ">
<Navbar/>
<ContactH/>
    </div>
</>

  )
}

export default Contact