import React from 'react'

const SlideText = (props) => {
 
 
 
 
 
  return (
    <section class="  w-full  " style={{ backgroundImage: "url('https://images.unsplash.com/photo-1640964828933-eb242cc56681?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1557&q=80')" }}>
    <div class=' box-border mx-0 mb-0 py-12  p-0 '>
      <div class=' uppercase  text-4xl whitespace-nowrap overflow-hidden'>
        <p class=' animate-scrollX '>
          <span class='    box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl'>
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name}
          </span>
          <span class='       box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl'>
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name}
          </span>
          <span class='       box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl'>
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name}
          </span>
          <span class='       box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl'>
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name}
          </span>
        </p>
        <p class='  animate-scrollZ  relative py-6 '>
          <span class='     box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl    shadow-transparent '
            style={{
              WebkitTextStroke: '0.5px #000',
              color: 'transparent'
            }}
          >{props.punchline}
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
          </span>
          <span class=' text-transparent  box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl  text-gray-300 stroke-black stroke-1' >{props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
            {props.punchline} -
          </span>
        </p>


        <p class='  animate-scrollX'>
          <span class='    box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl'>
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name}
          </span>
          <span class='    box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl'>
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name}
          </span>
          <span class='       box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl'>
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name}
          </span>
          <span class='       box-border inline-block font-semibold text-2xl m-0 p-0 lg:text-4xl'>
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name} -
            {props.name}
          </span>
        </p>
      </div>
    </div>
  </section>
  )
}

export default SlideText




