const unsplashUrls = [
    {
      url:
        "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2700&q=80",
      title: "Nike Free Flyknit 5.0",
      description:
        "A product microsite to showcase the 1990 ferrari concept designed by the industrial designer George.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1593081891731-fda0877988da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80",
      title: "Jordan Air 11",
      description:
        "A product microsite to showcase the 1990 ferrari concept designed by the industrial designer George.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1491553895911-0055eca6402d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1700&q=80",
      title: "Running Nike Shoe",
      description:
        "A product microsite to showcase the 1990 ferrari concept designed by the industrial designer George.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1597045566677-8cf032ed6634?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80",
      title: "Air Jordan 1",
      description:
        "A product microsite to showcase the 1990 ferrari concept designed by the industrial designer George.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1595950653106-6c9ebd614d3a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80",
      title: "Nike Air Force 1 Shadow",
      description:
        "A product microsite to showcase the 1990 ferrari concept designed by the industrial designer George.",
    },
    {
      url:
        "https://images.unsplash.com/photo-1603631540004-d7b2616b2323?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2632&q=80",
      title: "Ferrari 1990 concept",
      description:
        "A product microsite to showcase the 1990 ferrari concept designed by the industrial designer George.",
    },
  ];
  
  export default unsplashUrls;